<template>
    <b-form class="form" novalidate="novalidate" id="st_application_form" ref="applicationRegisterDetails"
        :class="{ 'st_application_form_edit': !viewMode }">
        <Loader v-if="isLoading" />
        <div>
            <generate-form v-if="formSchema" :data="formSchema" :value="formModels" ref="generateForm"
                @modelUpdated="updateFormData"></generate-form>
        </div>
        <div>
            <territorial-atlas v-if="showTerritorialAtlasComponent" :realEstates="realEstatesList"
                :gisFilesLayers="gisFilesLayers" :isNew="true" :isRealEstateTargetUsed.sync="isRealEstateTargetUsed"
                ref="territorialAtlas"></territorial-atlas>
        </div>
        <div>
            <!-- <payment-details v-if="formSchema" :paymentDetails="paymentDetails" :isRegister="true"
                @updatePaymentType="updatePaymentType" ref="paymentDetails"></payment-details> -->

            <discount-details v-if="showDiscountsSection" :viewMode="isPaid" :options="discountsOptions"
                :discountDetails="discountDetails" ref="discountDetails"
                @updateSelectedDiscount="updateSelectedDiscount"></discount-details>

            <application-summary :viewMode="viewMode" :formModels="formModels" :signDetails="signDetails"
                :paymentDetails="paymentDetails"
                :requiredRealEstate="requiredRealEstate" @child-mounted="applicationSummaryMounted"
                ref="applicationSummary"></application-summary>

        </div>
        <div class="d-flex justify-content-center mt-6">
            <st-button variant="primary" class="mr-4" :spinner="isLoading" :callback="doSave">
                <i class="fa fa-save"></i>
                {{ $t("APPLICATION.FORM.SAVE_AS_DRAFT_BUTTON") }}
            </st-button>
            <st-button variant="primary" :spinner="isLoading" :callback="doFinalize">
                <i class="fa fa-save"></i>
                {{ $t("APPLICATION.FORM.FINALIZE_BUTTON") }}
            </st-button>
        </div>
    </b-form>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import GenerateForm from "@/modules/forms/components/generate-form/GenerateForm.vue";
import ApplicationSummary from "@/modules/applications/components/summary/ApplicationSummary";
import Message from "@/shared/message/message";
import ApplicationFormCommonMixin from "@/modules/applications/mixins/application-form-common-mixin";
import TaxesStoreObserverMixin from "@/modules/applications/store/taxes-store-observer-mixin";

export default {
    name: "ApplicationDetailsTab",
    mixins: [TaxesStoreObserverMixin, ApplicationFormCommonMixin],
    components: {
        GenerateForm,
        ApplicationSummary,
        TerritorialAtlas: () => import(/* webpackChunkName: "atlas" */ "@/modules/applications/components/territorial-atlas/TerritorialAtlasCard.vue"),
        // PaymentDetails: () => import("@/modules/applications/components/payment/ApplicationPayment"),
        DiscountDetails: () => import("@/modules/applications/components/discount/ApplicationDiscount"),
    },
    props: {
        applicationId: String,
        requireAttachments: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            formSchema: null,
            formModels: {},
            fileStatus: {
                DRAFT: "draft",
                SIGNED: "signed",
                GENERATED: "generated",
                RESOLVED: "resolved",
                SEALED: "sealed"
            },
            initialFormModels: {},
            discountsOptions: null,
            isRealEstateTargetUsed: false,
            requiredRealEstate: false,
            hasGisAttachments: false,
            tax_category_conditions: null,
            discountDetails: null,
            isSubmitPending: false,
        };
    },
    computed: {
        ...mapGetters({
            record: "applications/form/record",
            fileToken: "applications/form/fileToken",
            applications: "applications/list/rows",
            loading: "shared/loading",
            files: "applications/form/combinedFiles",
            uploadAccessToken: "applications/form/uploadAccessToken",
            downloadToken: "applications/form/fileToken",
            currentUser: "auth/currentUser",
        }),
        viewMode() {
            return this.record?.status !== 'registered';
        },
        isLoading() {
            return (this.isSubmitPending || this.loading["applications/update"] || this.loading["applications/find"]);
        },
        sectionClass() {
            return "col-12";
        },
        isPaid() {
            return this.record?.is_paid;
        },
        isSigned() {
            return this.record?.is_signed;
        },
        gisFilesLayers() {
            return this.record?.gis_files_layers;
        },
        realEstatesList() {
            if (!this.formModels?.real_estate_target?.length) return [];
            return this.formModels.real_estate_target?.filter((el) => el?.coordinates?.length);
        },
        signDetails() {
            return (
                this.record?.sign_details ?? {
                    isOnline: false,
                    package: null,
                }
            );
        },
        paymentDetails() {
            return (
                this.record?.payment_details ?? {
                    payment_type: "OFFICE",
                }
            );
        },
        showTerritorialAtlasComponent() {
            return this.requiredRealEstate || this.hasGisAttachments;
        },
        showDiscountsSection() {
            return this.formSchema && this.discountsOptions?.length;
        },
    },
    methods: {
        ...mapActions({
            findFiles: "applications/form/findFiles",
            doFindApplicationType: "applications/form/findApplicationType",
            doFindForm: "applications/form/findForm",
            doUpload: "applications/form/upload",
            updateApplicationValidation: "applications/form/validation",
            doSendRegistry: "applications/form/registrySend",
            doUpdateRegistryApplication: "applications/form/updateRegistryApplicationForm",
            initializeOfflineSigning: "applications/form/initializeOfflineSigning",
            updateOfflineSignDownloadStatus: "applications/form/updateOfflineSignDownloadStatus",
            setComputedTaxFields: "applications/form/doSetComputedTaxFields",
            resetComputedTaxFields: "applications/form/doResetComputedTaxFields",
            downloadFileContent: "shared/downloadFileContent",
            doFindTaxCategory: "applications/form/findTaxCategory",
            calculateTaxes: "applications/form/calculateTaxes",
            doSetDiscountDetails: "applications/form/doSetDiscountDetails",
        }),
        async downloadFile(id) {
            // window.open(`${window.VUE_APP_API_URL}/files/${id}?token=${this.fileToken}`, "_blank");
            const url = `${window.VUE_APP_API_URL}/files/${id}`;
            const fileContent = await this.downloadFileContent({ url, token: this.fileToken });

            const _url = window.URL.createObjectURL(fileContent);
            window.open(_url, "_blank").focus();
        },
        updateSelectedDiscount(discountId) {
            const discountDetails = this.discountsOptions.find(option => option.id === discountId);
            this.discountDetails = discountDetails;
            this.doSetDiscountDetails(discountDetails);
        },
        generatedFormMounted() {
            this.$emit("form-mounted");
        },
        generatedMapLoaded() {
            this.$emit("map-mounted");
        },
        applicationSummaryMounted() {
            this.$emit("app-summary-mounted");
        },
        computeTaxFields(data, key) {
            if (this.is_tax_category_conditional && this.tax_category_conditions) {
                const TAX_RELATED_FIELDS = Object.keys(this.tax_category_conditions);
                if (TAX_RELATED_FIELDS.includes(key)) {
                    const payload = {
                        [key]: data,
                    };
                    this.setComputedTaxFields(payload);
                }
            }
        },
        clearFormData(data) {
            this.formModels = { ...this.formModels, ...data.form };
        },
        updateFormData(data, key, realEstateTargetUsed) {
            this.initialFormModels = Object.assign({}, this.formModels);
            Vue.set(this.formModels, key, data);
            this.isRealEstateTargetUsed = realEstateTargetUsed;
            if (this.formModels[key]) {
                this.syncFiles(data, key, this.formModels);
            }
            this.computeTaxFields(data, key);
        },
        async downloadPdfFile() {
            this.$emit("downloadPdfFile");
        },
        async applicationIsSignedOffline() {
            this.$emit("isSigned");
        },
        syncFiles(data, key, models) {
            //check if there are files
            const location = data?.length > 0 ? data : this.initialFormModels[key];
            if (Array.isArray(location)) {
                let hasFileKey = location?.filter((el) => el?.file_id);

                if (hasFileKey?.length > 0) {
                    const removedFiles = this.initialFormModels[key]?.filter(x => !models[key].map((el) => el.file_id).includes(x.file_id));
                    if (key === 'attachments') {
                        removedFiles?.forEach((file) => {
                            for (let index in models) {
                                if (index !== 'attachments' && Array.isArray(models[index]) && file?.file_id) {
                                    const fileIndex = models[index].findIndex((el) => el?.file_id === file.file_id);
                                    if (fileIndex !== -1) {
                                        this.$delete(models[index], fileIndex);
                                    }
                                }
                            }
                        });
                    } else {
                        removedFiles?.forEach((file) => {
                            if (file?.file_id) {
                                const fileIndex = models['attachments'].findIndex((el) => el?.file_id === file.file_id);
                                if (fileIndex !== -1) {
                                    this.$delete(models['attachments'], fileIndex);
                                    Vue.set(this.record, 'identification_files', models['attachments']);
                                }
                            }
                        })
                    }
                }
            }
        },
        async generateData(asDraft = false) {
            try {
                const genFormData = await this.$refs.generateForm.getData(asDraft);

                const { options, form } = genFormData;
                let formLables = {};

                for (let item in options) {
                    const opts = options[item].options;
                    if (opts?.length && typeof opts[0] === "object") {
                        const selectedOption = opts.find((opt) => opt.value === form[item]);
                        if (selectedOption) {
                            formLables[`${item}_label`] = selectedOption.label;
                        }
                    }
                }
                const formWithOptions = {
                    form: {
                        ...formLables,
                        ...form,
                        options,
                    },
                };
                return {
                    ...formWithOptions,
                };
            } catch (error) {
                this.updateApplicationValidation(false);
            }
        },
        async doSave(isFinalize = false) {
            try {
                this.isSubmitPending = true;
                let data = await this.generateData();
                if (!data) return false;

                data.payment_details = this.paymentDetails;
                data.discount_details = this.discountDetails;

                const fileDataObj = this.getFileData(data.form);
                const { fileItems, existentFiles } = fileDataObj;
                const fileData = fileItems;

                let application = this.record ?? null;
                if (!this.record?.id) {
                    application = await this.doCreate(data);
                }

                const uploadedFiles = [];
                let uploadedIdentificationFiles = [];
                let existingFiles = [];

                if (fileData && this.uploadAccessToken) {
                    this.identificationFilesUploaded = true;
                    this.isUploadingPending = true;
                    const promises = [];
                    for (let i = 0; i < Object.keys(fileData).length; i++) {
                        const inputFileName = Object.keys(fileData)[i];
                        if (inputFileName !== "identification_files") {
                            const files = fileData[inputFileName];
                            const currentUploadedFiles = await this.handleUploadFiles({
                                files,
                                uploadAccessToken: this.uploadAccessToken,
                            });
                            promises.push(currentUploadedFiles);
                            uploadedFiles[inputFileName] = currentUploadedFiles.map(
                                (el) => ({
                                    file_id: el.id,
                                    filename: el.initialFilename,
                                    created_date: el.created_date,
                                    file_type: "input",
                                    created_by: `${this.currentUser.first_name} ${this.currentUser.last_name}`,
                                })
                            );
                            uploadedIdentificationFiles = [
                                ...uploadedIdentificationFiles,
                                ...uploadedFiles[inputFileName]
                            ]
                        } else {
                            existingFiles = fileData["identification_files"];
                        }
                    }
                    Promise.all(promises)
                        .finally(() => {
                            this.isUploadingPending = false;
                        })
                }

                const appId = application?.data?.id ?? application.id;
                const fileToUpload = {}
                Object.keys(existentFiles).forEach((key) => {
                    if (uploadedFiles[key]) {
                        fileToUpload[key] = [...existentFiles[key], ...uploadedFiles[key]];
                    }
                })
                const attachments = [
                    ...existingFiles,
                    ...uploadedIdentificationFiles
                ];

                const formObj = {
                    ...data.form,
                    ...fileToUpload,
                    attachments: data?.form?.attachments && data.form.attachments.length ? data.form.attachments : attachments
                }

                if (this.record?.id && !this.record.gis_files?.length) {
                    data.gis_files_layers = [];
                }
                if (isFinalize) {
                    application = await this.doSendRegistry({
                        id: appId,
                        values: {
                            ...data,
                            identification_files: attachments,
                            form: formObj
                        },
                    });

                    this.$router.push({
                        name: "applications",
                    });
                } else {
                    application = await this.doUpdateRegistryApplication({
                        id: appId,
                        values: {
                            identification_files: attachments,
                            form: formObj
                        },
                    });
                }
                Message.success('GENERAL.UPDATED_SUCCESS');
            } catch (e) {
                throw e;
            } finally {
                this.isSubmitPending = false;
            }
        },
        async handleUploadFiles({ files, uploadAccessToken }) {
            try {
                return await this.doUpload({
                    files,
                    uploadAccessToken
                });
            } catch (e) {
                this.isUploadingPending = false;
                this.isPaymentPending = false;
                throw e;
            }
        },
        async doFinalize() {
            this.doSave(true);
        }
    },
    async created() {
        if (this.record?.application_type_id) {
            this.formModels = this.record.form;
            this.formModels.application_type_id = this.record.application_type_id;
            const {
                form_citizen_id,
                tax_category_conditions,
                is_tax_category_conditional,
                discounts,
                tax_category_id
            } = await this.doFindApplicationType(this.record.application_type_id);
            const formData = await this.doFindForm(form_citizen_id);
            this.formSchema = formData.schema;

            this.hasGisAttachments = !!formData.schema.list.find(item => item.type === 'gis-attachments');
            const show = !!formData.schema.list.find(item => item.type === 'real-estate-list');
            this.requiredRealEstate = show;
            if (!show) {
                this.generatedMapLoaded();
            }

            this.discountsOptions = discounts ?? null;

            if (is_tax_category_conditional) {
                this.tax_category_conditions = tax_category_conditions;
                this.is_tax_category_conditional = is_tax_category_conditional;

                const keys = Object.keys(tax_category_conditions);

                let conditionalKey;
                let conditionalData;
                for (let i = 0; i < keys.length; i++) {
                    const key = keys[i];
                    const data = this.formModels[key];
                    if (data) {
                        conditionalKey = key;
                        conditionalData = data;
                        break;
                    }
                }

                if (conditionalKey) {
                    this.computeTaxFields(conditionalData, conditionalKey);
                } else {
                    this.resetComputedTaxFields();
                }
            }
        }
    },
    watch: {
        "record.form": function (newVal) {
            this.formModels = { ...this.formModels, ...newVal };
        },
    },
};
</script>
